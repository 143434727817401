'use client'

import { useEffect } from 'react'
import Link from 'next/link'
import { toast } from 'sonner'

export function WelcomeToast() {
  useEffect(() => {
    if (!document.cookie.includes('welcome-to-347-facts-toast=347')) {
      toast('Welcome to 347 Facts!', {
        id: 'welcome-to-347-facts-toast',
        duration: Infinity,
        onDismiss: () => {
          document.cookie += 'welcome-to-347-facts-toast=347;max-age=31536000'
        },
        description: (
          <>
            Stay Informed on State Route 347 Projects.
            <hr className="my-2" />
            Support Riggs Rd and SR-347. Write to ADOT and our legislators!{' '}
            <Link href="/#contact" className="text-secondary-foreground">
              Take action now!
            </Link>
          </>
        ),
        closeButton: true,
      })
    }
  }, [])

  return null
}
