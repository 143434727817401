'use client'

import Link from 'next/link'

import { cn } from '@lib/utils'
import { Icons } from '@components/icons'

export function MainNav() {
  return (
    <div className="mr-4 hidden lg:flex">
      <Link href="/" className="mr-6 flex items-center space-x-2">
        <Icons.logo className="h-14 w-14" />
        <span className="hidden font-bold sm:inline-block">347 Facts</span>
      </Link>
      <nav className="flex items-center space-x-6 text-sm font-medium" aria-label="Global">
        <Link href="/contact" className={cn('transition-colors hover:text-foreground/80')}>
          Make Your Voice Heard - Fill Out the Form
        </Link>
        <Link
          href="/#currently-funded"
          className={cn('transition-colors hover:text-foreground/80')}
        >
          Currently Funded/Completed Items
        </Link>
        <Link href="/#faqs" className={cn('transition-colors hover:text-foreground/80')}>
          FAQs
        </Link>
      </nav>
    </div>
  )
}
